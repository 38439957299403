import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { oauthTeachbaseLink } from '@/core/consts/links';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    alias: '/courses',
    name: 'Courses',
    component: () => import('../views/Courses.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue'),
  //   meta: {title: 'Авторизация',},
  // },
  {
    path: '/course/:slug',
    name: 'Course',
    component: () => import('../views/Course.vue'),
    meta: {
      headerClass: 'border',
    },
    children: [
      {
        path: '/user/course/:slug',
        component: () => import('../views/Course.vue'),
        meta: {
          headerClass: 'border',
        },
      },
    ],
  },
  {
    path: '/program/:slug',
    name: 'Program',
    component: () => import('../views/Program.vue'),
    meta: {
      headerClass: 'border',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      headerClass: 'border',
    },
    children: [
      {
        path: '/profile/:tabName',
        component: () => import('../views/Profile.vue'),
        meta: {
          headerClass: 'border',
        },
      },
    ],
  },
  {
    path: '/survey/:slug',
    name: 'Surveys',
    component: () => import('../views/Surveys.vue'),
    meta: {
      headerClass: 'border',
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue'),
    meta: {
      headerClass: 'border',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/Courses.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters['user/isAuthenticated']) {
    checkToken();

    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl');
      next(redirectUrl);
    }
  }

  if (to.path === '/logout') {
    store.dispatch('user/logout').then();
  }

  if (to.path !== '/login' && !store.getters['user/isAuthenticated']) {
    localStorage.setItem('redirectUrl', to.path);

    window.location.href = oauthTeachbaseLink;
  } else if (to.path === '/login') {
    const {query,} = to;

    store.dispatch('user/login', {
      code: query.code,
      oauth_app_id: process.env.VUE_APP_TOCHKA_APP_ID,
    }).then((result) => {
      if (result.status === 200) {
        window.location.href='/';
      }
    });
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title : 'Точка';
  });
});

function checkToken() {
  const tokenTime = new Date(localStorage.getItem('tokenDate'));
  const timeDiff = new Date().getTime() - tokenTime.getTime();
  const diffHours = Math.ceil(timeDiff / (1000 * 3600));

  if (diffHours > 24) {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    window.location.href = oauthTeachbaseLink;
  } else {
    localStorage.setItem('tokenDate', new Date().toString());
  }
}

export default router;
