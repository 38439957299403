import Auth from '@/api/auth';
import User from '@/api/user';

const state = {
    token: localStorage.getItem('userToken') || null,
    userId: localStorage.getItem('userId') || null,
    user: null,
};

const getters = {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
};

const mutations = {
    setUserInfo(state, data) {
        state.user = data;
    },

    setToken(state, value) {
        state.token = value;
    },

    setId(state, value) {
        state.userId = value;
    },

    logout(state) {
        state.user = undefined;
    },
};

const actions = {
    async login({commit,}, user) {
        try {
            const response = await Auth.login(user);
            if (response?.data?.auth_token) {
                const token = response.data.auth_token;
                const id = response.data.id;
                console.log('response.data', response.data);

                localStorage.setItem('userToken', token);
                localStorage.setItem('userId', id);
                localStorage.setItem('tokenDate', new Date().toString());

                commit('setToken', token);
                commit('setId', id);
            }

            return response;
        } catch (error) {
            console.log('error', error);
        }
    },

    async logout({commit,}) {
        try {
            await Auth.logout(localStorage.getItem('userToken'));
            commit('logout');
        } catch (error) {
            console.log('error', error);
        } finally {
            localStorage.removeItem('userToken');
            localStorage.removeItem('userId');
            const teachbaseDomain = process.env.VUE_APP_TOCHKA_TB_DOMAIN;
            window.location.href = `https://${teachbaseDomain}/logout`;
        }

    },

    async getUser({commit,}) {
        let response;
        try {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('userToken');
            response = await User.getUserInfo(userId, token);
            commit('setUserInfo', response.data);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
