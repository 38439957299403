const teachbaseDomain = process.env.VUE_APP_TOCHKA_TB_DOMAIN;
const linkToCourse = `https://${teachbaseDomain}/course_sessions/`;

const oauth_app_id=process.env.VUE_APP_TOCHKA_APP_ID;
const callback_uri=process.env.VUE_APP_TOCHKA_CALLBACK_URI;
const tb_domain=process.env.VUE_APP_TOCHKA_TB_DOMAIN;

export const getLinkToCourse = (courseId) => {
    return courseId ? linkToCourse + courseId : null;
};

export const oauthTeachbaseLink = `https://${tb_domain}/oauth/authorize?client_id=${oauth_app_id}&redirect_uri=${callback_uri}&response_type=code&scope=default`;
