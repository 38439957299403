<template>
  <transition name="fade">
    <div class="modal-full modal-menu"
         id="modal-menu"
         style="display: block;"
         v-if="show">
      <div class="modal-container">
        <div class="modal-inner">
          <div class="modal-body">
            <div class="modal-close" @click="$emit('close')"></div>

            <div class="logo"></div>

            <div class="modal-content">

              <div class="main-menu-modal-block">
                <div class="content">
                  <div class="grid">
                    <div class="box">
                      <nav class="main-menu-modal">
                        <ul>
                          <li :class="{ active: $route.path === '/'}">
                            <a @click="routeTo('/')">Главная</a>
                          </li>
                          <li :class="{ active: $route.path === '/courses'}">
                            <a @click="routeTo('/courses')">Библиотека курсов</a>
                          </li>
                          <li :class="{ active: $route.path === '/faq'}">
                            <a @click="routeTo('/faq')">FAQ</a>
                          </li>
                          <li :class="{ active: $route.path === '/documents'}">
                            <a href="https://tochka.teachbase.ru/documents">Документы</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div class="box">
                      <div class="main-menu-modal">
                        <ul>
                          <li :class="{ active: $route.path === '/profile'}">
                            <a @click="routeTo('/profile')">Личный кабинет</a>
                          </li>
                          <li>
                            <a>Выйти</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'RouterMenuModal',
  props: {
    show: Boolean,
  },

  data() {
    return {};
  },

  mounted() {
  },

  watch: {},

  methods: {
    routeTo(routePath) {
      this.$router.push({path: routePath,});
      this.$emit('close');
    },
  },

  directives: {
    ClickOutside,
  },

  components: {},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
