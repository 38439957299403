<template>
  <header class="header" :class="$route.meta.headerClass">
    <div class="content">
      <div class="header-inner">
        <div class="burger" @click="isShowRouterMenu = true"></div>
        <div class="header-box">
          <router-link to="/" class="logo"></router-link>
          <nav class="main-menu">
            <ul>
              <li :class="{ active: $route.path === '/'}">
                <router-link to="/">Главная</router-link>
              </li>
              <li :class="{ active: $route.path === '/courses'}">
                <router-link to="/courses">
                  Библиотека курсов
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/faq'}">
                <router-link to="/faq">FAQ</router-link>
              </li>
              <li :class="{ active: $route.path === '/documents'}">
                <a href="https://tochka.teachbase.ru/documents">Документы</a>
              </li>
            </ul>
          </nav>
        </div>
        <div v-if="user" class="header-box">
          <div class="user-block"
               :class="{'active': isShowUserMenu}"
               v-click-outside="hideUserMenu">
            <div class="user-button" @click="isShowUserMenu = !isShowUserMenu">
              <div class="user-avatar"
                   :style="{'background-image': 'url(' + require('../assets/img/avatar-empty.svg') + ')'}"></div>

              <div class="user-name">{{ user.last_name }} {{ user.first_name }}</div>
              <div class="icon-item">
                <img src="@/assets/img/icons/icon-angle-down.svg" alt="">
                <img src="@/assets/img/icons/icon-angle-down-color.svg" alt="">
              </div>
            </div>
            <transition name="fade">
              <ul v-show="isShowUserMenu"
                  class="user-menu"
                  style="display: block;">
                <li>
                  <a @click="routeTo('/profile')">
                    <div class="icon-wrap">
                      <div class="icon-item">
                        <img src="@/assets/img/icons/icon-user.svg" alt="">
                        <img src="@/assets/img/icons/icon-user-color.svg" alt="">
                      </div>
                    </div>
                    Личный кабинет
                  </a>
                </li>
                <li>
                  <a @click="logout">
                    <div class="icon-wrap">
                      <div class="icon-item">
                        <img src="@/assets/img/icons/icon-exit.svg" alt="">
                        <img src="@/assets/img/icons/icon-exit-color.svg" alt="">
                      </div>
                    </div>
                    Выйти
                  </a>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <div class="modals">
      <router-menu-modal :show="isShowRouterMenu" @close="isShowRouterMenu = false"/>
    </div>
  </header>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import RouterMenuModal from '@/components/modals/RouterMenuModal';
import {mapGetters} from 'vuex';

export default {
  name: 'Header',
  props: {},

  data() {
    return {
      isShowUserMenu: false,
      isShowRouterMenu: false,
    };
  },

  mounted() {
  },

  watch: {},

  computed: {
    ...mapGetters('user', ['user',]),
  },

  methods: {
    hideUserMenu() {
      this.isShowUserMenu = false;
    },

    routeTo(routePath) {
      this.$router.push({path: routePath,});
      this.hideUserMenu();
    },

    logout() {
      this.$store.dispatch('user/logout');
    },
  },

  directives: {
    ClickOutside,
  },

  components: {
    RouterMenuModal,
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
