<template>
  <div id="app">
    <Header v-if="isHeaderShow"/>
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header';

export default {
  data() {
    return {
      isHeaderShow: false,
    };
  },

  created() {
  },

  mounted() {
  },

  methods: {},

  computed: {},

  watch: {
    $route(to) {
      this.isHeaderShow = to.path !== '/login';
    },
  },

  components: {Header,},
};
</script>

<style lang="scss">
@import "~@/assets/css/animate.css";
@import "~@/assets/css/slick.css";
@import "~@/assets/css/modal.css";
@import "~@/assets/css/select.css";
@import "~@/assets/css/compiled.css";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.profile-block {
  min-height: 1000px;
}
</style>
